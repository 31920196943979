import type { FC } from "react";
import { ReactComponent as ParachuteLogo } from "../assets/logo.svg";
import { ReactComponent as ParachuteLogoMinimal } from "../assets/logo_white.svg";

interface LogoProps {
  className: string;
  white?: boolean;
}

const Logo: FC<LogoProps> = ({ white, ...rest }) =>
  white ? <ParachuteLogoMinimal {...rest} /> : <ParachuteLogo {...rest} />;

export default Logo;
