import { ArticleSortValue } from "src/store/slices/articles";
import { CollectionsSortValue } from "src/store/slices/collections";
import parachuteApi from "../lib/axios";
import { Article } from "../types/articles";
import { PaginatedAndSorted } from "../types/base";
import { Collection, CollectionArticlesBody, CollectionBody, CurrentCollection } from "../types/collections";

export const fetchCollectionsService = async (): Promise<Collection[]> => {
  try {
    const response = await parachuteApi.get<Collection[]>("/v1/admin/collections");

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const postCollectionService = async (body: { title: string; articleIds: string[] }): Promise<Collection> => {
  try {
    const response = await parachuteApi.post<Collection>("/v1/admin/collections", body);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const publishCollectionService = async (collectionId: string): Promise<Collection> => {
  try {
    const response = await parachuteApi.post<Collection>(`/v1/admin/collections/${collectionId}/publish`);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const unpublishCollectionService = async (collectionId: string): Promise<Collection> => {
  try {
    const response = await parachuteApi.delete<Collection>(`/v1/admin/collections/${collectionId}/publish`);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateCollectionService = async (body: CollectionBody): Promise<CurrentCollection> => {
  try {
    const { collectionId, ...requestBody } = body;

    const response = await parachuteApi.put(`/v1/admin/collections/${collectionId}`, requestBody);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchSingleCollectionService = async (collectionId: string): Promise<Collection> => {
  try {
    const response = await parachuteApi.get(`/v1/admin/collections/${collectionId}`);

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const fetchSingleCollectionArticlesService = async (
  body: CollectionArticlesBody
): Promise<PaginatedAndSorted<Article, ArticleSortValue>> => {
  try {
    const { page, perPage, collectionId, order, sortBy } = body;
    const valueToSortBy = CollectionsSortValue[sortBy];
    const response = await parachuteApi.get<PaginatedAndSorted<Article, ArticleSortValue>>(
      `/v1/admin/collections/${collectionId}/articles`,
      {
        params: {
          page,
          perPage,
          order: order.toUpperCase(),
          sortBy: valueToSortBy,
        },
      }
    );

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const deleteCollectionService = async (collectionId: string): Promise<void> => {
  try {
    await parachuteApi.delete(`/v1/admin/collections/${collectionId}`);
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getAllArticleIdsForCurrentCollection = async (collectionId: string): Promise<string[]> => {
  try {
    const response = await parachuteApi.get(`/v1/admin/collections/${collectionId}/articles/ids`);

    return response.data.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const addArticleToCollections = async (articleId: string, body: string[]): Promise<void> => {
  try {
    await parachuteApi.put(`/v1/admin/collections/article/${articleId}`, { collectionIds: body });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
