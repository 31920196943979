import { Box, ButtonBase, Avatar, Popover, Button, RootRef } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    alignItems: "center",
    display: "flex",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  popoverCard: {
    width: 240,
  },
  buttonContainer: {
    padding: theme.spacing(2),
  },
}));

const AccountPopover: FC = () => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const { signOut } = useAuth();
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const toggleOpen = () => setOpen(current => !current);
  const logout = () => signOut();

  return (
    <>
      <RootRef rootRef={anchorRef}>
        <Box component={ButtonBase} onClick={toggleOpen} className={classes.avatarContainer}>
          <Avatar className={classes.avatar} />
        </Box>
      </RootRef>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={toggleOpen}
        open={open}
        PaperProps={{
          className: classes.popoverCard,
        }}
      >
        <Box className={classes.buttonContainer}>
          <Button color="primary" fullWidth onClick={logout} variant="outlined">
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
