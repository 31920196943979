import { ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import GlobalStyles from "./components/GlobalStyles";
import { THEMES } from "./constants";
import "./i18n.ts";
import routes from "./routes";
import { createTheme } from "./theme";

const App: FC = () => {
  const content = useRoutes(routes);

  const theme = createTheme({
    roundedCorners: true,
    theme: THEMES.LIGHT,
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider dense maxSnack={3}>
        <GlobalStyles />
        {content}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
