import { ActionRecurrence, ActionTypes, ActionUnitOfMeasure, UnitsOfMeasure } from "./types/actions";
import { BaseState, PaginatedDataBase } from "./types/base";
import {
  ArticlePillar,
  ArticleTag,
  ContentCategory,
  ContentFormat,
  ContentType,
  FocusArticleTags,
  GoalType,
} from "./types/general";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

export const onlySpecial = /^[^a-zA-Z0-9]+$/;
export const onlyLetters = /.*[a-zA-Z].*/;
export const onlyNumbers = /^[0-9]*$/;

export const DATE_FORMAT = "DD/MM/YYYY";

export const paths = {
  // Public
  root: "/",
  resetPassword: "reset-password",
  resetPasswordSuccess: "reset-password-success",
  notFound: "404",
  unauthorized: "401",
  serverError: "500",
  login: "login",

  // Secure
  organizations: "organizations",
  articles: "articles",
  articleType: "article-type",
  users: "users",
  collections: "collections",
  goals: "goals",
  actions: "actions",

  // Vars
  articleId: ":articleId",
  pillarId: ":pillarId",
  organizationId: ":organizationId",
  collectionId: ":collectionId",
  goalId: ":goalId",
  actionId: ":actionId",

  // Actions
  create: "create",
  contentCategory: ":contentCategory",
  view: "view",
  edit: "edit",
};

export const HTTPStatusCode = {
  success: 200,
  unauthorized: 401,
  notFound: 404,
  serverError: 500,
};

export const storageConstants = {
  token: "token",
};

export const MAX_IMAGE_SIZE = 5000000; // In bytes
export const MAX_INT = 2147483647; // Kotlin max int
export const SIDEBAR_WIDTH = 280; // in px
export const SECONDS_IN_AN_HOUR = 60;

export const articleTags: ArticleTag[] = [
  ArticleTag.anxiety,
  ArticleTag.breathe,
  ArticleTag.depression,
  ArticleTag.eat,
  ArticleTag.happiness,
  ArticleTag.mind,
  ArticleTag.move,
  ArticleTag.satisfaction,
  ArticleTag.sedentary,
  ArticleTag.sleep,
];

export const focusArticleTags: FocusArticleTags[] = [
  FocusArticleTags.active_habits,
  FocusArticleTags.active_nighttime_mind,
  FocusArticleTags.anxiety_exercises,
  FocusArticleTags.anxiety_strategies,
  FocusArticleTags.balance,
  FocusArticleTags.breathing_exercises,
  FocusArticleTags.calm_down,
  FocusArticleTags.calorie_needs,
  FocusArticleTags.challenge_yourself,
  FocusArticleTags.daily_exercise,
  FocusArticleTags.eat_routines,
  FocusArticleTags.eating_and_exercise,
  FocusArticleTags.eating_habits,
  FocusArticleTags.eating_timing,
  FocusArticleTags.end_of_day_routine,
  FocusArticleTags.enjoy_simple_things,
  FocusArticleTags.exercise_routines,
  FocusArticleTags.exercise_safety,
  FocusArticleTags.exercise,
  FocusArticleTags.feel_vigorous,
  FocusArticleTags.fifteen_minutes_workout,
  FocusArticleTags.find_your_pace,
  FocusArticleTags.food_effects,
  FocusArticleTags.food_sources,
  FocusArticleTags.healthy_cooking,
  FocusArticleTags.high_nutrition_food,
  FocusArticleTags.insomnia,
  FocusArticleTags.major_nutrients,
  FocusArticleTags.meal_preparation,
  FocusArticleTags.meditation,
  FocusArticleTags.mobility,
  FocusArticleTags.mood_improvement,
  FocusArticleTags.more_energy,
  FocusArticleTags.morning,
  FocusArticleTags.nature,
  FocusArticleTags.nutrition_plans,
  FocusArticleTags.plan_your_day,
  FocusArticleTags.recipes,
  FocusArticleTags.reduced_sleep,
  FocusArticleTags.relax,
  FocusArticleTags.sleep_habits,
  FocusArticleTags.sleeping_tips,
  FocusArticleTags.sport_types,
  FocusArticleTags.start_exercising,
  FocusArticleTags.stress_eating,
  FocusArticleTags.vegetarian_lifestyle,
  FocusArticleTags.work,
];

export const articlePillars: ArticlePillar[] = [
  ArticlePillar.eat,
  ArticlePillar.breathe,
  ArticlePillar.sleep,
  ArticlePillar.move,
  ArticlePillar.mind,
];

export const contentFormat: ContentFormat[] = [ContentFormat.article, ContentFormat.video, ContentFormat.podcast];

export const contentType: ContentType[] = [
  ContentType.activity,
  ContentType.research,
  ContentType.conditions,
  ContentType.symptoms,
  ContentType.recipe,
];

export const goalType: GoalType[] = [GoalType.generic, GoalType.recommended];

export const contentCategory: ContentCategory[] = [ContentCategory.maintain, ContentCategory.focus];

export const actionTypes: ActionTypes[] = [ActionTypes.walk, ActionTypes.sleep, ActionTypes.eat];

export const actionUnitsOfMeasure: UnitsOfMeasure = {
  WALK: [ActionUnitOfMeasure.steps],
  RUN: [ActionUnitOfMeasure.steps],
  SLEEP: [ActionUnitOfMeasure.hours, ActionUnitOfMeasure.minutes],
  EAT: [ActionUnitOfMeasure.count],
};

export const actionRecurrence: ActionRecurrence[] = [ActionRecurrence.daily, ActionRecurrence.forPeriod];

export const baseInitialState: BaseState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  fulfilledAction: null,
};

export const baseInitialPageState: PaginatedDataBase = {
  page: 0,
  perPage: 0,
  totalElements: 0,
};

export const initialPage = 0;
export const initialPerPage = 10;
