import { createStyles, makeStyles } from "@material-ui/core/styles";
import type { FC } from "react";
import "../theme/fonts/fonts.css";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,

        "&::-webkit-scrollbar": {
          width: "0.4em",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#D3D3D3",
          borderRadius: 20,
          outline: "none",
        },
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

const GlobalStyles: FC = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
