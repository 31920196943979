import type { Theme, ThemeOptions } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { THEMES } from "../constants";
import { lightShadows, darkShadows } from "./shadows";

interface ThemeConfig {
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  direction: "ltr",
  overrides: {
    MuiAvatar: {
      fallback: {
        height: "75%",
        width: "75%",
      },
    },
    MuiTableCell: {
      root: {
        padding: "12px 8px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#39449D",
      },
    },
    MuiTab: {
      wrapper: {
        color: "#02152B",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "auto",
        marginRight: "16px",
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'",
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.125rem",
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: {
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            opacity: 0.86,
            color: "#42526e",
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: "#6b778c",
      },
      background: {
        default: "#f4f5f7",
        paper: "#ffffff",
      },
      error: {
        contrastText: "#ffffff",
        main: "#DE374E",
      },
      primary: {
        contrastText: "#ffffff",
        main: "#39449D",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#02152B",
        secondary: "#6b778c",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#FA8928",
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    overrides: {
      MuiTableCell: {
        root: {
          borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
        },
      },
    },
    palette: {
      background: {
        default: "#171c24",
        paper: "#222b36",
      },
      divider: "rgba(145, 158, 171, 0.24)",
      error: {
        contrastText: "#ffffff",
        main: "#f44336",
      },
      type: "dark",
      primary: {
        contrastText: "#ffffff",
        main: "#688eff",
      },
      success: {
        contrastText: "#ffffff",
        main: "#4caf50",
      },
      text: {
        primary: "#ffffff",
        secondary: "#919eab",
      },
      warning: {
        contrastText: "#ffffff",
        main: "#ff9800",
      },
    },
    shadows: darkShadows,
  },
};

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {
      ...(config.roundedCorners && {
        shape: {
          borderRadius: 16,
        },
      }),
    })
  );

  return responsiveFontSizes(theme);
};
