import { combineReducers } from "@reduxjs/toolkit";
import actions from "./slices/actions";
import articles from "./slices/articles";
import auth from "./slices/auth";
import collections from "./slices/collections";
import formInput from "./slices/formInput";
import goals from "./slices/goals";
import organizations from "./slices/organizations";
import users from "./slices/users";

const rootReducer = combineReducers({
  articles,
  auth,
  collections,
  formInput,
  goals,
  organizations,
  users,
  actions,
});

export default rootReducer;
