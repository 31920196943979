import parachuteApi from "../lib/axios";
import { Article } from "../types/articles";
import { ImageResponse } from "../types/base";
import { ContentCategory } from "../types/general";

export const postImageService = async (image: null | File): Promise<null | ImageResponse> => {
  if (!image) {
    return null;
  }

  try {
    const formDataImage = new FormData();
    formDataImage.append("image", image);

    const response = await parachuteApi.post<ImageResponse>("/v1/contents", formDataImage, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getImageService = async (id: string): Promise<File> => {
  try {
    const response = await parachuteApi.get<File>(`/v1/contents/${id}`, {
      headers: {
        accept: "image/jpeg",
      },
    });

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const publishArticleService = async (id: string): Promise<Article> => {
  try {
    const response = await parachuteApi.post<Article>(`/v1/admin/articles/${id}/publish`);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const unPublishArticleService = async (id: string): Promise<Article> => {
  try {
    const response = await parachuteApi.delete<Article>(`/v1/admin/articles/${id}/publish`);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getAllArticleIds = async (): Promise<string[]> => {
  try {
    const response = await parachuteApi.get("/v1/admin/articles/ids", {
      params: { contentCategory: ContentCategory.maintain },
    });

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
