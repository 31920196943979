import { AppBarProps, AppBar, Toolbar, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../Logo";
import AccountPopover from "./AccountPopover";

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const useStyles = makeStyles(theme => ({
  appBar: {
    ...(theme.palette.type === "light" && {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "none",
      color: theme.palette.primary.contrastText,
    }),
    ...(theme.palette.type === "dark" && {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: "none",
    }),
    zIndex: theme.zIndex.drawer + 100,
  },
  toolbar: {
    minHeight: 64,
  },
  logo: {
    height: 55,
    width: 110,
  },
  wrapper: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  container: {
    marginLeft: theme.spacing(2),
  },
}));

const DashboardNavbar: FC<DashboardNavbarProps> = props => {
  const { className, ...other } = props;
  const classes = useStyles();

  return (
    <AppBar className={clsx(className, classes.appBar)} {...other}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to={"/"}>
          <Logo white className={classes.logo} />
        </RouterLink>
        <Box className={classes.wrapper} />
        <Box className={classes.container}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
