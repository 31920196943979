import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseInitialState, baseInitialPageState } from "../../constants";
import {
  createOrganizationService,
  getOrganizationsService,
  sendInviteToOrganizationService,
} from "../../services/organizations";
import { PaginatedDataBase, PaginatedState, PaginationWithSorting, Sort } from "../../types/base";
import { FulfilledEnum } from "../../types/general";
import { Organization } from "../../types/organization";
import { RootState } from "../index";

export enum OrganizationsSortValue {
  organizationName = "ORGANIZATION_NAME",
  timeCreated = "TIME_CREATED",
  lastUpdated = "LAST_UPDATED",
}

export type PaginatedOrganizationsData = PaginatedDataBase &
  Sort<Organization> & {
    all: Organization[];
  };
type OrganizationsState = PaginatedState<PaginatedOrganizationsData>;

const initialState: OrganizationsState = {
  data: {
    all: [],
    order: "asc",
    sortBy: "organizationName",
    ...baseInitialPageState,
  },
  ...baseInitialState,
};

// NOTE: SEND INVITE
const sendInvitation = createAsyncThunk<void, { email: string; organizationId: string }, { state: RootState }>(
  "organization/send_invite",
  async (data, thunk) => {
    try {
      const response = await sendInviteToOrganizationService(data.organizationId, data.email);

      if (response.status === 201) {
        return;
      } else {
        thunk.rejectWithValue(response.data);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { organizations } = getState();

      return !organizations.isFetching;
    },
  }
);

// NOTE: CREATE ORGANIZATION
const createOrganization = createAsyncThunk<Organization, string, { state: RootState }>(
  "organization/create",
  async (organizationName, thunk) => {
    try {
      const response = await createOrganizationService(organizationName);
      if (response.status === 200) {
        return response.data;
      } else {
        thunk.rejectWithValue(response.data);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { organizations } = getState();

      return !organizations.isFetching;
    },
  }
);

// NOTE: GET ALL ORGANIZATIONS
const getOrganizations = createAsyncThunk<
  PaginatedOrganizationsData,
  PaginationWithSorting<Organization>,
  { state: RootState }
>(
  "organization/fetch",
  async ({ perPage = 10, page = 0, order, sortBy }, thunk) => {
    try {
      const response = await getOrganizationsService(page, perPage, undefined, order, sortBy);

      if (response.status === 200) {
        const { data, totalElements } = response.data;
        return {
          all: data,
          page,
          perPage,
          order,
          sortBy,
          totalElements,
        };
      } else {
        thunk.rejectWithValue(response.data);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { organizations } = getState();

      return !organizations.isFetching;
    },
  }
);

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    clearState: state => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;
      state.errorMessage = "";
    },
  },
  extraReducers: builder => {
    builder
      // Note: Get organizations
      .addCase(getOrganizations.pending, state => {
        state.isFetching = true;
        state.isSuccess = false;
      })
      .addCase(getOrganizations.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      })
      // Note: Send invitation
      .addCase(sendInvitation.pending, state => {
        state.isFetching = true;
      })
      .addCase(sendInvitation.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      .addCase(sendInvitation.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.fulfilledAction = FulfilledEnum.CREATE;
      })

      // Note: Create organization
      .addCase(createOrganization.pending, state => {
        state.isFetching = true;
        state.isSuccess = false;
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      .addCase(createOrganization.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.CREATE;
        state.data.all = [...state.data.all];
      });
  },
});

export const organizationsActions = {
  sendInvitation,
  createOrganization,
  getOrganizations,
  clear: organizationsSlice.actions.clearState,
};

export const organizationSelector = (state: RootState): OrganizationsState => state.organizations;

export default organizationsSlice.reducer;
