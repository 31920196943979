import { Box, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FC } from "react";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFF",
    minHeight: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoadingScreen: FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress color="primary" size={100} />
    </Box>
  );
};

export default LoadingScreen;
