import { FieldErrors } from "react-hook-form";
import { getI18n } from "react-i18next";
import { onlyLetters, onlySpecial, SECONDS_IN_AN_HOUR } from "../constants";
import { ActionUnitOfMeasure } from "../types/actions";
import { HTMLArticleResponse } from "../types/articles";

export const bytesToSize = (bytes: number, decimals = 2): string => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function isDisabledValidation<TType>(data: TType, errors: FieldErrors<TType>): boolean {
  const keys = Object.keys(data);

  return keys.some(key => {
    const value = data[key];

    return key in errors || !value || ((typeof value === "string" || Array.isArray(value)) && !value.length);
  });
}

export const toBase64 = (file: File): Promise<ArrayBuffer | string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const stripHtml = (
  html: string,
  pillars: string[],
  tags: string[],
  contentFormat: string,
  contentType: string,
  contentCategory: string
): HTMLArticleResponse => {
  const temporalDivElement = document.createElement("div");

  temporalDivElement.innerHTML = html;

  const title = temporalDivElement.querySelector("#title").textContent;
  const description = temporalDivElement.querySelector("#description").textContent;
  const author = temporalDivElement.querySelector("#author").textContent;
  const imgSelector = temporalDivElement.querySelector("img#article-cover");
  const img = imgSelector ? imgSelector.getAttribute("src") : null;
  const content = temporalDivElement.querySelector("#content").innerHTML.trim();
  return {
    title,
    author,
    description,
    img,
    content,
    pillars,
    tags,
    contentFormat,
    contentType,
    contentCategory,
  };
};

export const htmlBuilder = (data: Record<string, string | string[]>): string => {
  if (
    !data &&
    !data.title &&
    !data.author &&
    !data.description &&
    !data.content &&
    !data.tags &&
    !data.contentFormat &&
    !data.contentType &&
    !data.contentCategory
  ) {
    return;
  }
  const translation = getI18n();

  const pillars = (data.pillars as string[])
    .map((value, index) => {
      return `<span class="chip chip-${value.toLowerCase()}" key=${index}><span class="chip-text">${translation.getResource(
        "en",
        "articles",
        `create.form.pillar.options.${value}`
      )}</span></span>`;
    })
    .join(" ")
    .split(",");

  const body = `
  <!DOCTYPE html>
   <html lang="en">

    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      
      <link rel="preconnect" href="https://fonts.gstatic.com">

      <link href="//cdn.quilljs.com/1.3.6/quill.core.css" rel="stylesheet">

      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&family=Roboto:ital,wght@0,400;0,500;1,400;1,500;1,900&display=swap"
        rel="stylesheet">
      <style>
      :root {
        --primary-color: #02152B;
        --secondary-text-color: #9B9C9F;
        --background-color: #FFFFFF;
        --description-color: #000000;
        --title-font: "AvenirRoman", sans-serif;
        --author-font: "AvenirMedium", sans-serif;
        --content-font: "AvenirBook", sans-serif;
        --base-line-height: 26px;
        --content-line-height: 19.8pt;
      }

      @font-face {
        font-family: "AvenirBook";
        font-weight: normal;
        font-style: normal;
        src: url("https://parachutestaticbucket-parachutestaticbucket-2n98gktotw42.s3.eu-west-1.amazonaws.com/AvenirBook.ttf");
      }
  
      @font-face {
        font-family: "AvenirMedium";
        font-weight: normal;
        font-style: normal;
        src: url("https://parachutestaticbucket-parachutestaticbucket-2n98gktotw42.s3.eu-west-1.amazonaws.com/AvenirMedium.ttf");
      }
  
      @font-face {
        font-family: "AvenirRoman";
        font-weight: normal;
        font-style: normal;
        src: url("https://parachutestaticbucket-parachutestaticbucket-2n98gktotw42.s3.eu-west-1.amazonaws.com/AvenirRoman.ttf");
      }
  
      body {
        margin: 0;
        padding: 0;
        color: var(--primary-color);
        background-color: var(--background-color);
      }


      h1, h2, h3, h4, h5, h6, p, div, a {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--primary-color);
        font-weight: normal;
        line-height: var(--content-line-height);
    }

    h1 {
      font-size: 22pt;
    }

    h1 {
      line-height: calc(var(--base-line-height) * 1.5);
    }

    h2 {
      line-height: calc(var(--base-line-height) * 1.2);
    }

    h5 {
      line-height: calc(var(--base-line-height) / 1.5);
    }
  
      body .container {
        min-height: 100vh;
        background-color: var(--background-color);
        display: flex;
        flex-direction: column;
      }

      body .container .content-format {
        display: flex;
        margin: 1.5rem 24pt 0rem 24pt;
        gap: 1rem;
        flex-direction: column;
        color: var(--secondary-text-color);
        font-size: 10pt;
        font-family: var(--author-font);
        letter-spacing: 0.4pt;
      }
  
      body .container .title-box {
        display: flex;
        margin: 1rem 24pt 1rem 24pt;
        gap: 1rem;
        flex-direction: column;
      }
  
      body .container .title-box .title {
        color: var(--primary-color);
        font-size: 22pt;
        font-family: var(--title-font);
        line-height: 30.8pt;
        word-wrap: break-word;
      }

      body .container .pillars {
        font-family: var(--content-font);
        margin-bottom: 40pt;
        margin-left: 24pt;
        margin-right: 24pt;
      }

      body .container .pillars .chip {
        color: #fff;
        margin-bottom: 2.5rem;
        border-radius: 24pt;
        font-size: 12pt;
        line-height: 20pt;
      }

      body .container .pillars .chip .chip-text {
        padding-left: 9pt;
        padding-right: 9pt;
        padding-top: 4pt;
        padding-bottom: 4pt;
      }

      body .container .pillars .chip-breathe {
        background-color: #A2C9FC;
      }
      body .container .pillars .chip-eat {
        background-color: #05795B;
      }
      body .container .pillars .chip-move {
        background-color: #F9703E;
      }
      body .container .pillars .chip-mind {
        background-color: #D5C7E2;
      }
      body .container .pillars .chip-sleep {
        background-color: #463A89;
      }

  
      body .container .author {
        font-size: 16pt;
        line-height: 30pt;
        font-family: var(--author-font);
        color: var(--description-color);
        margin: 0 24pt;
      }
  
      body .container .author-container {
        font-size: 16pt;
        line-height: 30pt;
        font-family: var(--author-font);
        color: var(--description-color);
        margin: 0 24pt;
      }

      body .container .description {
        font-size: 16pt;
        line-height: 30pt;
        font-family: var(--author-font);
        color: var(--primary-color);
        margin: 0 24pt;
      }
  
      body .container img.article-cover {
        height: auto;
        max-width: 100%;
        margin: 1rem 1rem 0rem 1rem;
        border-radius: 16pt;
      }
  
      body .container .content {
        margin: 11.8pt 24pt;
        font-size: 14pt;
        font-family: var(--content-font);
        line-height: var(--content-line-height);
        color: var(--primary-color);
        background-color: var(--background-color);
        padding: 0;
        white-space: normal;
      }
  
      body .container .content p img {
        object-fit: contain;
        width: 100%;
      }

      body .container .content .ql-video-wrapper {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
      }

      body .container .content .ql-video-wrapper iframe.ql-video{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
      
      body .container .content blockquote {
        border-left: 4px solid #ccc;
        margin-bottom: 5px;
        margin-top: 5px;
        padding-left: 16px;
      }
      
      body .container .content p span.ql-size-huge {
        font-size: 1.5rem;
        font-weight: bold;
      }
      
      body .container .content p.ql-align-center {
        text-align: center;
      }
  
      body .container .content p.ql-align-left {
        text-align: left;
      }
  
      body .container .content p.ql-align-right {
        text-align: right;
      }

      .ql-editor .ql-indent-1 {
        padding-left: 2em !important;
      }
      .ql-editor .ql-indent-2 {
        padding-left: 4em !important;
      }
      .ql-editor .ql-indent-3 {
        padding-left: 6em !important;
      }
      .ql-editor .ql-indent-4 {
        padding-left: 8em !important; 
      }
      .ql-editor .ql-indent-5 {
        padding-left: 8.5em !important;
      }
      .ql-editor .ql-indent-6 {
        padding-left: 9em !important;
      }
      .ql-editor .ql-indent-7 {
        padding-left: 9.5em !important;
      }
      .ql-editor .ql-indent-8 {
        padding-left: 10em !important;
      }

      </style>
      <title>${data.title} | Parachute</title>
    </head>

    <body>
      <div class="container">
      ${data.img ? `<img src="${data.img}" alt="article-image" class="article-cover" id="article-cover">` : ""}
      <div class="content-format">${translation.getResource(
        "en",
        "articles",
        `create.form.contentFormat.options.${data.contentFormat}`
      )}</div>
        <div class="title-box">
          <h1
            class="title"
            id="title">${data.title}
          </h1>
        </div>
        <div class="pillars">${pillars}</div>
        <div class="author" id="author">${data.author}</div>

        <div hidden class="description" id="description">
        ${data.description}
        </div>
        <div hidden class="author-container">
          <span>Created By</span>
          <span id="author">${data.author}</span>
        </div>
    `;

  const content = `
    <div id="content" class="content ql-editor">
      ${data.content}
    </div>
  </div>
</body>
</html>
  `;

  return body + content;
};

export const checkLengthAndSpecial = (value: string, minLength: number): "lengthError" | "specialError" | true => {
  const trimmed = value.trim();

  if (onlySpecial.test(trimmed)) {
    return "specialError";
  }

  if (trimmed.length < minLength) {
    return "lengthError";
  }

  return true;
};

export const validateString = (
  value: string,
  messageMinLength: string,
  messageAtleastOneLetter: string,
  maxLength?: number | null,
  messageMaxLength?: string
): string | boolean => {
  const trimmed = value.trim();

  if (!trimmed || trimmed.length < 3) {
    return messageMinLength;
  }

  if (maxLength && trimmed.length > maxLength) {
    return messageMaxLength;
  }

  if (!onlyLetters.test(trimmed)) {
    return messageAtleastOneLetter;
  }

  return true;
};

export function setFormValuesFunction(
  content: Record<string, string | string[]>,
  setValue: (key: string, value: string) => void,
  returnValue = false
): void | string {
  Object.keys(content).forEach(key => {
    if (typeof content[key] === "string" && !Array.isArray(content[key])) {
      const trimmedValue = (content[key] as string).trim();
      setValue(key, trimmedValue);
      return;
    }
    if (returnValue) {
      return content[key];
    }
    setValue(key, content[key] as string);
  });
}

export const secondsToCurrentMeasure = (seconds: number, unit: string): number => {
  switch (unit) {
    case ActionUnitOfMeasure.minutes:
      return seconds / SECONDS_IN_AN_HOUR;
    case ActionUnitOfMeasure.hours:
      return seconds / SECONDS_IN_AN_HOUR / SECONDS_IN_AN_HOUR;
    default:
      return;
  }
};
