import axios from "axios";
import config from "../config";

const parachuteApi = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export default parachuteApi;
