import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import parachuteApi from "../../lib/axios";
import { BaseState } from "../../types/base";
import { FulfilledEnum } from "../../types/general";
import { RootState } from "../index";

type CurrentAuth = {
  token: string;
};

interface AuthState extends BaseState {
  current: CurrentAuth | null;
}

const initialState: AuthState = {
  current: null,
  isFetching: false,
  isSuccess: false,
  isError: false,
  fulfilledAction: null,
  errorMessage: "",
};

export const login = createAsyncThunk<
  string,
  { email: string; password: string },
  { state: RootState; rejectValue: string }
>(
  "auth/login",
  async ({ email, password }, thunk) => {
    try {
      const response = await parachuteApi.post<{ access_token: string; role: "ADMIN" | "USER" }>("user/sessions", {
        password,
        username: email,
      });

      if (response.status === 201 && response.data.role === "ADMIN") {
        return response.data.access_token;
      }

      return thunk.rejectWithValue("login:errors.notAdmin");
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          return thunk.rejectWithValue("login:errors.invalid");
        }

        return thunk.rejectWithValue("login:errors.generic");
      }

      throw new Error(e);
    }
  },
  {
    condition: (_, { getState }) => {
      const { auth } = getState();

      return !auth.isFetching;
    },
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: state => {
      state.fulfilledAction = FulfilledEnum.DELETE;
      state.isSuccess = true;
      state.current = null;
    },
    clearState: state => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;
      state.errorMessage = null;
    },
    addToken: (state, action: PayloadAction<string>) => {
      state.fulfilledAction = FulfilledEnum.UPDATE;
      state.isSuccess = true;
      state.current = {
        ...state.current,
        token: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.isFetching = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.GET;
        state.current = {
          ...state.current,
          token: action.payload,
        };
      });
  },
});

export const authActions = {
  login,
  logout: authSlice.actions.logout,
  clear: authSlice.actions.clearState,
  addToken: authSlice.actions.addToken,
};

export const authSelector = (state: RootState): AuthState => state.auth;

export default authSlice.reducer;
