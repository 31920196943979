import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addArticleToCollections } from "src/services/collections";
import { baseInitialPageState, baseInitialState } from "../../constants";
import parachuteApi from "../../lib/axios";
import { postImageService, publishArticleService, unPublishArticleService } from "../../services/articles";
import { Article, HTMLResponse } from "../../types/articles";
import {
  BaseEntity,
  ImageResponse,
  PaginatedAndSorted,
  PaginatedDataBase,
  PaginatedState,
  PaginationWithSortingAndContentCategory,
  Sort,
} from "../../types/base";
import { ContentCategory, FulfilledEnum } from "../../types/general";
import { htmlBuilder } from "../../utils/functions";
import { RootState } from "../index";

interface CurrentArticle extends BaseEntity {
  pillars: string[];
  html: string;
  image: ImageResponse | null;
  isPublished: boolean;
  tags: string[];
  contentFormat: string;
  contentType: string;
  contentCategory: string;
  isSequential: boolean;
}

interface ArticlePayload {
  current: CurrentArticle;
  article: Article;
}

export enum ArticleSortValue {
  title = "TITLE",
  contentFormat = "FORMAT",
  contentType = "TYPE",
  isPublished = "IS_PUBLISHED",
  lastUpdated = "LAST_UPDATED",
}

export type PaginatedArticlesData = PaginatedDataBase &
  Sort<Article> & {
    all: Article[];
    current: CurrentArticle | null;
  };

type ArticlesState = PaginatedState<PaginatedArticlesData>;

const initialState: ArticlesState = {
  data: {
    all: [],
    current: null,
    order: "asc",
    sortBy: "title",
    ...baseInitialPageState,
  },
  ...baseInitialState,
};

// NOTE: GET SINGLE
const getSingleArticle = createAsyncThunk<CurrentArticle, string, { state: RootState; rejectValue: string }>(
  "articles/single/fetch",
  async (id, thunk) => {
    try {
      const articleResponse = await parachuteApi.get<Article>(`/v1/admin/articles/${id}`);
      const article = articleResponse.data;
      const htmlResponse = await parachuteApi.get<string>(`/v1/contents/html/${article.content.id}`, {
        headers: {
          accept: "text/html",
        },
      });

      return {
        id: article.id,
        pillars: article.pillars,
        isPublished: article.isPublished,
        image: article.image,
        html: htmlResponse.data,
        tags: article.tags,
        contentFormat: article.contentFormat,
        contentType: article.contentType,
        contentCategory: article.contentCategory,
        isSequential: article.isSequential || false,
      };
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          return thunk.rejectWithValue("You are unauthorized to make such request");
        }

        if (e.response.status === 404) {
          return thunk.rejectWithValue("Article with such ID does not exist");
        }
      }

      throw new Error(e.response.data.message);
    }
  }
);

// NOTE: GET ALL
const getArticles = createAsyncThunk<
  PaginatedArticlesData,
  PaginationWithSortingAndContentCategory<Article>,
  { state: RootState; rejectValue: string }
>(
  "articles/fetch",
  async ({ page = 0, perPage = 10, order, sortBy, contentCategory }, thunk) => {
    try {
      const { articles } = thunk.getState();
      const valueToSortBy = ArticleSortValue[sortBy];

      const response = await parachuteApi.get<PaginatedAndSorted<Article, ArticleSortValue>>("/v1/admin/articles", {
        params: {
          page,
          perPage,
          order: order.toUpperCase(),
          sortBy: valueToSortBy,
          contentCategory,
        },
      });

      const { data, totalElements } = response.data;
      return {
        all: data,
        page,
        perPage,
        order,
        sortBy: valueToSortBy,
        totalElements,
        current: articles.data.current,
      };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { articles } = getState();

      return !articles.isFetching;
    },
  }
);

// NOTE: CREATE
const createArticle = createAsyncThunk<ArticlePayload, void, { state: RootState; rejectValue: string }>(
  "articles/create",
  async (_, thunk) => {
    try {
      const { formInput, articles } = thunk.getState();

      const img = formInput ? formInput.img : null;

      const html = htmlBuilder({ ...formInput, img });

      const uploadHtml = await parachuteApi.post<HTMLResponse>("/v1/contents/html", {
        html,
      });

      if (uploadHtml.status !== 200) {
        return thunk.rejectWithValue("Something went wrong when uploading the article");
      }

      const content = uploadHtml.data;
      const imageId = articles?.data?.current?.image ? articles?.data?.current?.image?.id : null;

      const uploadArticle = await parachuteApi.post<Article>("/v1/admin/articles", {
        title: formInput.title,
        contentId: content.id,
        pillars: formInput.pillars,
        imageId,
        tags: formInput.tags,
        contentFormat: formInput.contentFormat,
        contentType: formInput.contentType,
        contentCategory: formInput.contentCategory,
        isSequential: formInput.isSequential || false,
      });

      if (uploadArticle.status !== 200) {
        return thunk.rejectWithValue("Something went wrong when uploading the article");
      }

      const uploadArticleData = uploadArticle.data;

      const current: CurrentArticle = {
        html,
        pillars: uploadArticleData.pillars,
        id: uploadArticleData.id,
        image: articles?.data?.current?.image,
        tags: uploadArticleData.tags,
        isPublished: uploadArticleData.isPublished,
        contentFormat: uploadArticleData.contentFormat,
        contentType: uploadArticleData.contentType,
        contentCategory: uploadArticleData.contentCategory,
        isSequential: formInput.isSequential ? JSON.parse(formInput.isSequential as string) : false,
      };

      if (formInput.contentCategory === ContentCategory.maintain) {
        await addArticleToCollections(uploadArticleData.id, formInput.collections as string[]);
      }

      return {
        current,
        article: uploadArticleData,
      };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { articles } = getState();

      return !articles.isFetching;
    },
  }
);

// NOTE: UPDATE
const editArticle = createAsyncThunk<ArticlePayload, void, { state: RootState; rejectValue: string }>(
  "articles/edit",
  async (_, thunk) => {
    try {
      const { articles, formInput } = thunk.getState();
      if (articles.data.current.isPublished) {
        await unPublishArticleService(articles.data.current.id);
      }

      const html = htmlBuilder({ ...formInput, img: formInput.img });

      const uploadHtml = await parachuteApi.post<HTMLResponse>("/v1/contents/html", {
        html,
      });

      if (uploadHtml.status !== 200) {
        return thunk.rejectWithValue("Unable to upload the edited article");
      }

      const content = uploadHtml.data;
      const imageId = articles.data.current.image ? articles.data.current.image.id : null;

      const uploadEditedArticle = await parachuteApi.put<Article>(`/v1/admin/articles/${articles.data.current.id}`, {
        title: formInput.title,
        imageId,
        contentId: content.id,
        tags: formInput.tags,
        pillars: formInput.pillars,
        contentType: formInput.contentType,
        contentFormat: formInput.contentFormat,
        contentCategory: formInput.contentCategory,
        isSequential: formInput.isSequential || false,
      });

      if (uploadEditedArticle.status !== 200) {
        return thunk.rejectWithValue("Unable to upload the edited article");
      }

      const uploadArticleData = uploadEditedArticle.data;

      const current: CurrentArticle = {
        html,
        pillars: uploadArticleData.pillars,
        id: uploadArticleData.id,
        image: articles.data.current.image,
        tags: uploadArticleData.tags,
        isPublished: uploadArticleData.isPublished,
        contentFormat: uploadArticleData.contentFormat,
        contentType: uploadArticleData.contentType,
        contentCategory: uploadArticleData.contentCategory,
        isSequential: formInput.isSequential ? JSON.parse(formInput.isSequential as string) : false,
      };
      return {
        current,
        article: uploadArticleData,
      };
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  },
  {
    condition: (article, { getState }) => {
      const { articles, formInput } = getState();

      return !articles.isFetching || articles.data.current.id === formInput.articleId;
    },
  }
);

// Note: Publish article
const publishArticle = createAsyncThunk<Article, string, { state: RootState; rejectValue: string }>(
  "articles/publish",
  async (id, thunk) => {
    try {
      const response = await publishArticleService(id);

      return response;
    } catch (e) {
      thunk.rejectWithValue(e.message);
    }
  },
  {
    condition: (id, thunk) => {
      const { articles } = thunk.getState();

      return !articles.isFetching || articles.data.current.id !== id || articles.data.current.isPublished;
    },
  }
);

// NOTE: Un-Publish article
const unPublishArticle = createAsyncThunk<Article, string, { state: RootState; rejectValue: string }>(
  "articles/remove",
  async (id, thunk) => {
    try {
      const response = await unPublishArticleService(id);

      return response;
    } catch (e) {
      thunk.rejectWithValue(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { articles } = getState();

      return !articles.isFetching;
    },
  }
);

const addPhotoInput = createAsyncThunk<ImageResponse, File, { state: RootState; rejectValue: string }>(
  "articles/addPhoto",
  async (image, thunk) => {
    try {
      const img = await postImageService(image);

      return img;
    } catch (e) {
      if (e.response) {
        thunk.rejectWithValue(e.message);
      }

      throw new Error(e.message);
    }
  }
);

const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    clearState: (state, { payload = false }: PayloadAction<boolean>) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;

      if (payload) {
        state.data.current = null;
      }
    },
  },
  extraReducers: builder => {
    builder
      // Note: Get single article
      .addCase(getSingleArticle.pending, state => {
        state.isFetching = true;
      })
      .addCase(getSingleArticle.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getSingleArticle.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      })

      // Note: Get all articles
      .addCase(getArticles.pending, state => {
        state.isFetching = true;
      })
      .addCase(getArticles.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getArticles.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      })

      // Note: Create an article
      .addCase(createArticle.pending, state => {
        state.isFetching = true;
      })
      .addCase(createArticle.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(createArticle.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.CREATE;
        state.data.current = action.payload.current;
        state.data.all = [...state.data.all, action.payload.article];
      })
      // Note: Upload Photo
      .addCase(addPhotoInput.pending, state => {
        state.isFetching = true;
      })
      .addCase(addPhotoInput.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(addPhotoInput.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.data.current = {
          ...state.data.current,
          image: action.payload,
        };
      })

      // Note: Edit an article
      .addCase(editArticle.pending, state => {
        state.isFetching = true;
      })
      .addCase(editArticle.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(editArticle.fulfilled, (state, action) => {
        const foundIndex = state.data.all.findIndex(article => article.id === action.payload.article.id);

        if (foundIndex !== -1) {
          state.data.all[foundIndex] = action.payload.article;
        }
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
        state.data.current = action.payload.current;
      })

      //  Note: UnPublish an article
      .addCase(unPublishArticle.pending, state => {
        state.isFetching = true;
      })
      .addCase(unPublishArticle.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(unPublishArticle.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
        state.data.current = { ...state.data.current, isPublished: false };

        const foundIndex = state.data.all.findIndex(article => article.id === action.payload.id);
        if (foundIndex !== -1) {
          state.data.all[foundIndex] = { ...state.data.all[foundIndex], isPublished: false };
        }
      })

      //  Note: Publish an article
      .addCase(publishArticle.pending, state => {
        state.isFetching = true;
      })
      .addCase(publishArticle.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = action.error.message;
      })
      .addCase(publishArticle.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
        state.data.current = { ...state.data.current, isPublished: true };

        const foundIndex = state.data.all.findIndex(article => article.id === action.payload.id);
        if (foundIndex !== -1) {
          state.data.all[foundIndex] = { ...state.data.all[foundIndex], isPublished: true };
        }
      });
  },
});

export const articlesActions = {
  publishArticle,
  getSingleArticle,
  getArticles,
  createArticle,
  unPublishArticle,
  addPhotoInput,
  editArticle,
  clear: articlesSlice.actions.clearState,
};

export const articlesSelector = (state: RootState): ArticlesState => state.articles;

export default articlesSlice.reducer;
