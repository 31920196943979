import parachuteApi from "src/lib/axios";
import { GoalsSortValue } from "src/store/slices/goals";
import { OrderType, PaginatedAndSorted } from "src/types/base";
import { Goal, GoalContent } from "src/types/goals";

export const postGoalsService = async (body: GoalContent): Promise<Goal> => {
  try {
    const response = await parachuteApi.post<Goal>("/v1/admin/goals", body);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const fetchGoalsService = async (
  page: number,
  perPage: number,
  order: OrderType,
  sortBy: keyof Goal
): Promise<PaginatedAndSorted<Goal, GoalsSortValue>> => {
  try {
    const valueToSortBy = GoalsSortValue[sortBy];
    const response = await parachuteApi.get<PaginatedAndSorted<Goal, GoalsSortValue>>("/v1/admin/goals", {
      params: {
        page,
        perPage,
        order: order.toUpperCase(),
        sortBy: valueToSortBy,
      },
    });
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const fetchSingleGoalService = async (goalId: string): Promise<Goal> => {
  try {
    const response = await parachuteApi.get<Goal>(`/v1/admin/goals/${goalId}`);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const publishGoalService = async (goalId: string): Promise<Goal> => {
  try {
    const response = await parachuteApi.post<Goal>(`/v1/admin/goals/${goalId}/publish`);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const unpublishGoalService = async (goalId: string): Promise<Goal> => {
  try {
    const response = await parachuteApi.delete<Goal>(`/v1/admin/goals/${goalId}/publish`);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const updateGoalService = async (goalId: string, body: GoalContent): Promise<Goal> => {
  try {
    const response = await parachuteApi.put(`/v1/admin/goals/${goalId}`, body);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
