import { Box, Button, Collapse, ListItem, ListItemProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import { useState, FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";

interface NavItemPropsBase extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

type NavItemProps = Omit<NavItemPropsBase, "button">;

const useStyles = makeStyles(theme => ({
  branchListItem: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  branchButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    paddingRight: "8px",
    paddingTop: 12,
    paddingBottom: 12,
    textAlign: "left",
    textTransform: "none",
    width: "100%",
  },
  activeBranchButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& svg": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },
  buttonTitle: {
    flexGrow: 1,
  },
  leafListItem: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  leafButton: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    textAlign: "left",
    paddingRight: 8,
    paddingTop: 12,
    paddingBottom: 12,
    textTransform: "none",
    width: "100%",
  },
  activeLeafButton: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    "& svg": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },
}));

const NavItem: FC<NavItemProps> = props => {
  const classes = useStyles();
  const { active = false, children, depth, icon, info, open: openProp = false, path, title, ...other } = props;
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem disableGutters className={classes.branchListItem} {...other}>
        <Button
          endIcon={
            !open ? (
              <ChevronRight fontSize="small" />
            ) : (
              <ChevronRight style={{ transform: "rotate(90deg)" }} fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          style={{ paddingLeft: `${paddingLeft}px` }}
          className={clsx(classes.branchButton, { [classes.activeBranchButton]: active })}
          variant="text"
        >
          <Box className={classes.buttonTitle}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem disableGutters className={classes.leafListItem}>
      <Button
        component={path && RouterLink}
        startIcon={icon}
        style={{ paddingLeft: `${paddingLeft}px` }}
        className={clsx(classes.leafButton, { [classes.activeLeafButton]: active })}
        variant="text"
        to={path}
      >
        <Box className={classes.buttonTitle}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};

export default NavItem;
