import { AxiosResponse } from "axios";

import { OrganizationsSortValue } from "src/store/slices/organizations";
import { OrderType } from "src/types/base";
import parachuteApi from "../lib/axios";
import { PaginatedAndSorted } from "../types/base";
import { Organization } from "../types/organization";

export const sendInviteToOrganizationService = async (
  organizationId: string,
  receiverEmail: string
): Promise<AxiosResponse> => {
  try {
    return await parachuteApi.post(`/v1/organizations/${organizationId}/invitations`, {
      receiverEmail,
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const createOrganizationService = async (organizationName: string): Promise<AxiosResponse<Organization>> => {
  try {
    return await parachuteApi.post<Organization>("/v1/organizations", { organizationName });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getOrganizationsService = async (
  page: number,
  perPage: number,
  organizationName: string | undefined = undefined,
  order?: OrderType,
  sortBy?: keyof Organization
): Promise<AxiosResponse<PaginatedAndSorted<Organization, OrganizationsSortValue>>> => {
  try {
    const valueToSortBy = OrganizationsSortValue[sortBy];

    return await parachuteApi.get<PaginatedAndSorted<Organization, OrganizationsSortValue>>("/v1/organizations", {
      params: {
        page,
        perPage,
        organizationName,
        order: order ? order.toUpperCase() : undefined,
        sortBy: sortBy ? valueToSortBy : undefined,
      },
    });
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};
