import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Outlet } from "react-router-dom";
import { SIDEBAR_WIDTH } from "../../constants";
import { useSelector } from "../../store";
import { authSelector } from "../../store/slices/auth";
import LoadingScreen from "../LoadingScreen";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: "64px",
    paddingLeft: `${SIDEBAR_WIDTH}px`,
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    position: "relative",
    WebkitOverflowScrolling: "touch",
  },
}));

const DashboardLayout: React.FC = (): JSX.Element | null => {
  const classes = useStyles();

  const { isFetching: authFetching } = useSelector(authSelector);

  return authFetching ? (
    <LoadingScreen />
  ) : (
    <Box className={classes.root}>
      <DashboardNavbar />
      <DashboardSidebar />
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
