import parachuteApi from "../lib/axios";
import { ActionsSortValue } from "../store/slices/actions";
import { Actions, ActionRequestParams } from "../types/actions";
import { OrderType, PaginatedAndSorted } from "../types/base";

export const getActionsService = async (
  page: number,
  perPage: number,
  order: OrderType,
  sortBy: keyof Actions
): Promise<PaginatedAndSorted<Actions, ActionsSortValue>> => {
  try {
    const valueToSortBy = ActionsSortValue[sortBy];

    const actions = await parachuteApi.get<PaginatedAndSorted<Actions, ActionsSortValue>>("/v1/admin/actions", {
      params: {
        page,
        perPage,
        order: order.toUpperCase(),
        sortBy: valueToSortBy,
      },
    });
    return actions.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getSingleActionService = async (actionId: string): Promise<Actions> => {
  try {
    const action = await parachuteApi.get<Actions>(`/v1/admin/action/${actionId}`);

    return action.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const postActionsService = async (body: ActionRequestParams): Promise<Actions> => {
  try {
    const response = await parachuteApi.post<Actions>("/v1/admin/action", body);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const editActionsService = async (actionId: string, body: ActionRequestParams): Promise<Actions> => {
  try {
    const response = await parachuteApi.put<Actions>(`/v1/admin/action/${actionId}`, body);
    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const publishActionService = async (actionId: string): Promise<Actions> => {
  try {
    const response = await parachuteApi.put<Actions>(`/v1/admin/action/${actionId}/publish`);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const unpublishActionService = async (actionId: string): Promise<Actions> => {
  try {
    const response = await parachuteApi.put<Actions>(`/v1/admin/action/${actionId}/unpublish`);

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

const ActionsService = {
  getActionsService,
  postActionsService,
  editActionsService,
  getSingleActionService,
  publishActionService,
  unpublishActionService,
};

export default ActionsService;
