import { ResourceKey } from "i18next";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { paths, storageConstants } from "../constants";
import parachuteApi from "../lib/axios";
import { useDispatch, useSelector } from "../store";
import { authActions, authSelector } from "../store/slices/auth";
import { FulfilledEnum } from "../types/general";

type AuthReturnType = {
  signIn: (email: string, password: string) => void;
  signOut: () => void;
  ping: () => void;
};

const useAuth = (): AuthReturnType => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { current, isError, isSuccess, fulfilledAction, errorMessage } = useSelector(authSelector);

  useEffect(() => {
    if (isSuccess) {
      switch (fulfilledAction) {
        case FulfilledEnum.GET:
          localStorage.setItem(storageConstants.token, current.token);
          parachuteApi.defaults.headers.common["Authorization"] = current.token;
          dispatch(authActions.clear());
          navigate(paths.root);
          break;

        case FulfilledEnum.DELETE:
          localStorage.removeItem(storageConstants.token);
          delete parachuteApi.defaults.headers.common["Authorization"];
          dispatch(authActions.clear());
          navigate(`/${paths.login}`);
          break;

        case FulfilledEnum.UPDATE:
          parachuteApi.defaults.headers.common["Authorization"] = current.token;
          dispatch(authActions.clear());
          break;

        default:
          return;
      }
    }

    if (isError) {
      const key = enqueueSnackbar(t(errorMessage as ResourceKey), {
        variant: "error",
        onClick: () => closeSnackbar(key),
      });
      dispatch(authActions.clear());
      return;
    }
  }, [isError, isSuccess]);

  const signIn = (email: string, password: string) => dispatch(authActions.login({ email, password }));
  const signOut = () => dispatch(authActions.logout());
  const ping = () => {
    if (!current || !current.token) {
      const existingToken = localStorage.getItem(storageConstants.token);

      if (!existingToken) {
        dispatch(authActions.clear());
        navigate(`/${paths.login}`);
        return;
      }

      dispatch(authActions.addToken(existingToken));
    }
  };

  return { signIn, ping, signOut };
};

export default useAuth;
