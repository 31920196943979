import { Box, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import useNavSections from "../../hooks/useNavSections";
import NavSection from "../NavSection";

const useStyles = makeStyles(theme => ({
  containerBase: {
    backgroundColor: theme.palette.background.paper,
    width: 280,
  },
  desktopContainer: {
    height: "calc(100% - 64px) !important",
    top: "64px !important",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  logoLink: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  mobileLogo: {
    height: 110,
    width: 220,
  },
  navSectionContainer: {
    margin: theme.spacing(2),
  },
  navSection: {
    "& + &": {
      marginTop: theme.spacing(3),
    },
  },
}));

const DashboardSidebar: FC = () => {
  const location = useLocation();
  const sections = useNavSections();
  const classes = useStyles();

  const content = (
    <Box className={classes.contentContainer}>
      <Box className={classes.navSectionContainer}>
        {sections.map(section => (
          <NavSection key={section.title} pathname={location.pathname} className={classes.navSection} {...section} />
        ))}
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      PaperProps={{
        className: clsx(classes.containerBase, classes.desktopContainer),
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
