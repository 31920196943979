import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { articlesActions } from "./articles";

type FormInputState = Record<string, string | string[]> | null;

const initialState: FormInputState = null;

const formInputSlice = createSlice({
  name: "formInput",
  initialState,
  reducers: {
    addFormInput: (state, { payload }: PayloadAction<Record<string, string | string[]>>) => {
      if (!payload) {
        return state;
      }

      if (!state) {
        return (state = payload);
      }

      return {
        ...state,
        ...payload,
      };
    },
    clearState: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(articlesActions.addPhotoInput.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          img: action.payload.url,
        };
      }
    });
  },
});

export const formInputActions = {
  addFormInput: formInputSlice.actions.addFormInput,
  clear: formInputSlice.actions.clearState,
};

export const formInputSelector = (state: RootState): FormInputState => state.formInput;

export default formInputSlice.reducer;
