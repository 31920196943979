import { FC } from "react";

export enum UserType {
  invited = "INVITED",
  active = "ACTIVE",
}

export enum FontFamilyAvenir {
  AVENIR_BOOK = "AvenirBook",
  AVENIR_MEDIUM = "AvenirMedium",
  AVENIR_ROMAN = "AvenirRoman",
}

export type Steps = {
  label: string;
  component: FC<FormProps>;
};

export interface FormProps {
  onNext: () => void;
  onBack: () => void;
  activeStep: number;
  allStepsCompleted: boolean;
  isFormCompleted: boolean;
  isEditMode: boolean;
}

export enum ArticleTag {
  anxiety = "ANXIETY",
  breathe = "BREATHE",
  depression = "DEPRESSION",
  eat = "EAT",
  happiness = "HAPPINESS",
  mind = "MIND",
  move = "MOVE",
  satisfaction = "SATISFACTION",
  sedentary = "SEDENTARY",
  sleep = "SLEEP",
}

export enum FocusArticleTags {
  active_habits = "ACTIVE_HABITS",
  active_nighttime_mind = "ACTIVE_NIGHTTIME_MIND",
  anxiety_exercises = "ANXIETY_EXERCISES",
  anxiety_strategies = "ANXIETY_STRATEGIES",
  balance = "BALANCE",
  breathing_exercises = "BREATHING_EXERCISES",
  calm_down = "CALM_DOWN",
  calorie_needs = "CALORIE_NEEDS",
  challenge_yourself = "CHALLENGE_YOURSELF",
  daily_exercise = "DAILY_EXERCISE",
  eat_routines = "EAT_ROUTINES",
  eating_and_exercise = "EATING_AND_EXERCISE",
  eating_habits = "EATING_HABITS",
  eating_timing = "EATING_TIMING",
  end_of_day_routine = "END_OF_DAY_ROUTINE",
  enjoy_simple_things = "ENJOY_SIMPLE_THINGS",
  exercise = "EXERCISE",
  exercise_routines = "EXERCISE_ROUTINES",
  exercise_safety = "EXERCISE_SAFETY",
  feel_vigorous = "FEEL_VIGOROUS",
  fifteen_minutes_workout = "FIFTEEN_MINUTES_WORKOUT",
  find_your_pace = "FIND_YOUR_PACE",
  food_effects = "FOOD_EFFECTS",
  food_sources = "FOOD_SOURCES",
  healthy_cooking = "HEALTHY_COOKING",
  high_nutrition_food = "HIGH_NUTRITION_FOOD",
  insomnia = "INSOMNIA",
  major_nutrients = "MAJOR_NUTRIENTS",
  meal_preparation = "MEAL_PREPARATION",
  meditation = "MEDITATION",
  mobility = "MOBILITY",
  mood_improvement = "MOOD_IMPROVEMENT",
  more_energy = "MORE_ENERGY",
  morning = "MORNING",
  nature = "NATURE",
  nutrition_plans = "NUTRITION_PLANS",
  plan_your_day = "PLAN_YOUR_DAY",
  recipes = "RECIPES",
  reduced_sleep = "REDUCED_SLEEP",
  relax = "RELAX",
  sleep_habits = "SLEEP_HABITS",
  sleeping_tips = "SLEEPING_TIPS",
  sport_types = "SPORT_TYPES",
  start_exercising = "START_EXERCISING",
  stress_eating = "STRESS_EATING",
  vegetarian_lifestyle = "VEGETARIAN_LIFESTYLE",
  work = "WORK",
}

export enum FulfilledEnum {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  GET = "GET",
}

export enum ArticlePillar {
  eat = "EAT",
  breathe = "BREATHE",
  sleep = "SLEEP",
  move = "MOVE",
  mind = "MIND",
}

export enum ContentFormat {
  article = "ARTICLE",
  video = "VIDEO",
  podcast = "PODCAST",
}

export enum ContentType {
  activity = "ACTIVITY",
  research = "RESEARCH",
  conditions = "CONDITIONS",
  symptoms = "SYMPTOMS",
  recipe = "RECIPE",
}

export enum ContentCategory {
  maintain = "MAINTAIN",
  focus = "FOCUS",
}

export enum GoalType {
  generic = "GENERIC",
  recommended = "RECOMMENDED",
}
