import { BaseEntity } from "./base";

export interface ActionRequestParams {
  title: string;
  description: string;
  completionThreshold: number;
  durationInDays: number;
  type: string;
  recurrence: string;
  isDeviceNeeded: boolean;
  focusTags: string[];
  unitOfMeasure: string;
}

export type Actions = ActionRequestParams &
  BaseEntity & {
    isPublished: boolean;
    isOutdatedVersion: boolean;
  };

export enum ActionTypes {
  walk = "WALK",
  // run = "RUN",
  sleep = "SLEEP",
  eat = "EAT",
}

export enum ActionRecurrence {
  daily = "DAILY",
  forPeriod = "FOR_PERIOD",
}

export enum ActionUnitOfMeasure {
  // meters = "METERS",
  // kilometers = "KILOMETERS",
  steps = "STEPS",
  hours = "HOURS",
  minutes = "MINUTES",
  count = "COUNT",
}

export type UnitsOfMeasure = {
  WALK: ActionUnitOfMeasure[];
  RUN: ActionUnitOfMeasure[];
  SLEEP: ActionUnitOfMeasure[];
  EAT: ActionUnitOfMeasure[];
};
