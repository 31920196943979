import { FC, lazy, Suspense, useEffect } from "react";
import type { PartialRouteObject } from "react-router";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import { HTTPStatusCode, paths } from "./constants";
import useAuth from "./hooks/useAuth";
import { useSelector } from "./store";
import { authSelector } from "./store/slices/auth";

export type GeneralPageProps = {
  title?: string;
  description?: string;
  button?: string;
  status?: number;
};

const Loadable = Component => (props: GeneralPageProps) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Secure: FC = ({ children }) => {
  const { ping } = useAuth();
  const auth = useSelector(authSelector);

  useEffect(() => {
    ping();
  }, [auth.current]);

  return <>{children}</>;
};

// Auth
const Login = Loadable(lazy(() => import("./pages/authentication/LoginPage")));
const PasswordReset = Loadable(lazy(() => import("./pages/authentication/ResetPasswordPage")));

// Error
const HTTPStatus = Loadable(lazy(() => import("./pages/HTTPStatusPage")));

// Dashboard
const DashboardPage = Loadable(lazy(() => import("./pages/DashboardPage")));

// Organizations
const OrganizationsPage = Loadable(lazy(() => import("./pages/organizations/OrganizationsPage")));

// Users
const UsersPage = Loadable(lazy(() => import("./pages/users/UsersPage")));

// Articles
const ArticlesPage = Loadable(lazy(() => import("./pages/articles/ArticlesPage")));
const CreatArticlePage = Loadable(lazy(() => import("./pages/articles/CreateArticlePage")));
const ViewArticlePage = Loadable(lazy(() => import("./pages/articles/ViewArticlePage")));
const EditArticlePage = Loadable(lazy(() => import("./pages/articles/EditArticlePage")));
const ArticleType = Loadable(lazy(() => import("./pages/articles/NewComponents/ArticleType")));

// Collections
const CollectionsPage = Loadable(lazy(() => import("./pages/collections/CollectionsPage")));
const CreateCollectionPage = Loadable(lazy(() => import("./pages/collections/CreateCollectionPage")));
const ViewCollectionPage = Loadable(lazy(() => import("./pages/collections/ViewCollectionPage")));
const EditCollectionPage = Loadable(lazy(() => import("./pages/collections/EditCollectionPage")));

//Goals
const GoalsPage = Loadable(lazy(() => import("./pages/goals/GoalsPage")));
const ViewGoalPage = Loadable(lazy(() => import("./pages/goals/ViewGoalPage")));
const CreateGoalPage = Loadable(lazy(() => import("./pages/goals/CreateGoalPage")));
const EditGoalPage = Loadable(lazy(() => import("./pages/goals/EditGoalPage")));

//Actions
const ActionsPage = Loadable(lazy(() => import("./pages/actions/ActionsPage")));
const CreateActionPage = Loadable(lazy(() => import("./pages/actions/CreateActionPage")));
const ViewActionPage = Loadable(lazy(() => import("./pages/actions/ViewActionPage")));
const EditActionPage = Loadable(lazy(() => import("./pages/actions/EditActionPage")));

const routes: PartialRouteObject[] = [
  // Public routes
  {
    path: "*",
    children: [
      {
        path: paths.resetPassword,
        element: <PasswordReset />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: paths.resetPasswordSuccess,
        element: (
          <HTTPStatus
            title={"HTTP:resetPasswordSuccess.title"}
            description={"HTTP:resetPasswordSuccess.description"}
            button={"HTTP:resetPasswordSuccess.button"}
            status={HTTPStatusCode.success}
          />
        ),
      },
      {
        path: paths.unauthorized,
        element: (
          <HTTPStatus
            title={"HTTP:401.title"}
            description={"HTTP:401.description"}
            button={"HTTP:401.button"}
            status={HTTPStatusCode.unauthorized}
          />
        ),
      },
      {
        path: paths.notFound,
        element: (
          <HTTPStatus
            title={"HTTP:404.title"}
            description={"HTTP:404.description"}
            button={"HTTP:404.button"}
            status={HTTPStatusCode.notFound}
          />
        ),
      },
      {
        path: paths.serverError,
        element: (
          <HTTPStatus
            title={"HTTP:500.title"}
            description={"HTTP:500.description"}
            button={"HTTP:500.button"}
            status={HTTPStatusCode.serverError}
          />
        ),
      },
      {
        path: "*",
        element: (
          <HTTPStatus
            title={"HTTP:404.title"}
            description={"HTTP:404.description"}
            button={"HTTP:404.button"}
            status={HTTPStatusCode.notFound}
          />
        ),
      },
    ],
  },

  // Private Routes
  {
    path: paths.root,
    element: <DashboardLayout />,
    children: [
      {
        path: paths.root,
        element: (
          <Secure>
            <DashboardPage />
          </Secure>
        ),
      },
      {
        path: paths.organizations,
        children: [
          {
            path: paths.root,
            element: (
              <Secure>
                <OrganizationsPage />
              </Secure>
            ),
          },
          {
            path: `${paths.organizationId}/${paths.users}`,
            element: (
              <Secure>
                <UsersPage />
              </Secure>
            ),
          },
        ],
      },
      {
        path: paths.articles,
        children: [
          {
            path: paths.root,
            element: (
              <Secure>
                <ArticlesPage />
              </Secure>
            ),
          },
          {
            path: `${paths.articleType}`,
            element: (
              <Secure>
                <ArticleType />
              </Secure>
            ),
          },
          {
            path: `${paths.create}/${paths.contentCategory}`,
            element: (
              <Secure>
                <CreatArticlePage />
              </Secure>
            ),
          },
          {
            path: `${paths.articleId}/${paths.edit}`,
            element: (
              <Secure>
                <EditArticlePage />
              </Secure>
            ),
          },
          {
            path: `${paths.articleId}/${paths.view}`,
            element: (
              <Secure>
                <ViewArticlePage />
              </Secure>
            ),
          },
        ],
      },
      {
        path: paths.collections,
        children: [
          {
            path: paths.root,
            element: (
              <Secure>
                <CollectionsPage />
              </Secure>
            ),
          },
          {
            path: paths.create,
            element: (
              <Secure>
                <CreateCollectionPage />
              </Secure>
            ),
          },
          {
            path: `${paths.collectionId}/${paths.view}`,
            element: (
              <Secure>
                <ViewCollectionPage />
              </Secure>
            ),
          },
          {
            path: `${paths.collectionId}/${paths.edit}`,
            element: (
              <Secure>
                <EditCollectionPage />
              </Secure>
            ),
          },
        ],
      },
      {
        path: paths.goals,
        children: [
          {
            path: paths.root,
            element: (
              <Secure>
                <GoalsPage />
              </Secure>
            ),
          },
          {
            path: paths.create,
            element: (
              <Secure>
                <CreateGoalPage />
              </Secure>
            ),
          },
          {
            path: `${paths.goalId}/${paths.view}`,
            element: (
              <Secure>
                <ViewGoalPage />
              </Secure>
            ),
          },
          {
            path: `${paths.goalId}/${paths.edit}`,
            element: (
              <Secure>
                <EditGoalPage />
              </Secure>
            ),
          },
        ],
      },
      {
        path: paths.actions,
        children: [
          {
            path: paths.root,
            element: (
              <Secure>
                <ActionsPage />
              </Secure>
            ),
          },
          {
            path: paths.create,
            element: (
              <Secure>
                <CreateActionPage />
              </Secure>
            ),
          },
          {
            path: `${paths.actionId}/${paths.view}`,
            element: (
              <Secure>
                <ViewActionPage />
              </Secure>
            ),
          },
          {
            path: `${paths.actionId}/${paths.edit}`,
            element: (
              <Secure>
                <EditActionPage />
              </Secure>
            ),
          },
        ],
      },
    ],
  },
];

export default routes;
