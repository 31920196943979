import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseInitialPageState, baseInitialState } from "src/constants";
import { PaginatedDataBase, PaginatedState, PaginationWithSorting, Sort } from "src/types/base";
import { Goal } from "src/types/goals";
import {
  fetchGoalsService,
  fetchSingleGoalService,
  postGoalsService,
  publishGoalService,
  unpublishGoalService,
  updateGoalService,
} from "../../services/goals";
import { FulfilledEnum } from "../../types/general";
import { RootState } from "../index";

export type PaginatedGoalsState = Sort<Goal> & {
  all: Goal[];
  current: Goal | null;
};
export type PaginatedGoalsData = PaginatedGoalsState & PaginatedDataBase;

type GoalsNewState = PaginatedState<PaginatedGoalsData>;

const initialState: GoalsNewState = {
  data: {
    all: [],
    current: null,
    order: "asc",
    sortBy: "title",
    ...baseInitialPageState,
  },
  ...baseInitialState,
};
export enum GoalsSortValue {
  title = "TITLE",
  isPublished = "IS_PUBLISHED",
  type = "TYPE",
}

// Note: GET ALL
const getGoals = createAsyncThunk<
  PaginatedGoalsData,
  PaginationWithSorting<Goal>,
  { state: RootState; rejectValue: string }
>(
  "goals/fetch",
  async ({ page = 0, perPage = 10, order, sortBy }, thunk) => {
    try {
      const { goals } = thunk.getState();
      const response = await fetchGoalsService(page, perPage, order, sortBy);
      const { data, totalElements } = response;
      return {
        all: data,
        page,
        perPage,
        order,
        sortBy,
        totalElements,
        current: goals.data.current,
      };
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { goals } = getState();

      return !goals.isFetching;
    },
  }
);

// // Note: GET SINGLE
const getSingleGoal = createAsyncThunk<Goal, string, { state: RootState; rejectValue: string }>(
  "goals/fetch_single",
  async (goalId, thunk) => {
    try {
      const goal = await fetchSingleGoalService(goalId);

      return goal;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  }
);
// Note: CREATE
const createGoal = createAsyncThunk<Goal, void, { state: RootState; rejectValue: string }>(
  "goals/create",
  async (_, thunk) => {
    try {
      const { formInput } = thunk.getState();

      const body = {
        title: formInput.title as string,
        description: formInput.description as string,
        type: formInput.type as string,
        focusTags: formInput.focusTags as string[],
        maintainTags: formInput.maintainTags as string[],
        pillars: formInput.pillars as string[],
        hasSequentialContent: formInput.hasSequentialContent
          ? JSON.parse(formInput.hasSequentialContent as string)
          : false,
      };

      const goal = await postGoalsService(body);

      return goal;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { goals } = getState();

      return !goals.isFetching;
    },
  }
);
// Note: PUBLISH
const publishGoal = createAsyncThunk<void, string, { state: RootState; rejectValue: string }>(
  "goals/publish",
  async (goalId, thunk) => {
    try {
      await publishGoalService(goalId);
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { goals } = getState();

      return !goals.isFetching;
    },
  }
);
// Note: UNPUBLISH
const unpublishGoal = createAsyncThunk<void, string, { state: RootState; rejectValue: string }>(
  "goals/unpublish",
  async (goalId, thunk) => {
    try {
      await unpublishGoalService(goalId);
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { goals } = getState();

      return !goals.isFetching;
    },
  }
);

// Note: UPDATE
const updateGoal = createAsyncThunk<Goal, void, { state: RootState; rejectValue: string }>(
  "goals/update",
  async (_, thunk) => {
    try {
      const { formInput, goals } = thunk.getState();
      const goalId = goals.data.current.id;

      const body = {
        title: formInput.title as string,
        description: formInput.description as string,
        type: formInput.type as string,
        focusTags: formInput.focusTags as string[],
        maintainTags: formInput.maintainTags as string[],
        pillars: formInput.pillars as string[],
        hasSequentialContent: formInput.hasSequentialContent
          ? JSON.parse(formInput.hasSequentialContent as string)
          : false,
      };

      const updatedGoal = await updateGoalService(goalId, body);

      return updatedGoal;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { goals } = getState();

      return !goals.isFetching;
    },
  }
);

const goalsSlice = createSlice({
  name: "goals",
  initialState,
  reducers: {
    clearState: (state, { payload = false }: PayloadAction<boolean>) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;

      if (payload) {
        state.data.current = null;
      }
    },
  },
  extraReducers: builder => {
    builder
      // Note: Get all
      .addCase(getGoals.pending, state => {
        state.isFetching = true;
      })
      .addCase(getGoals.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(getGoals.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.data = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      })

      // Note: Get Single
      .addCase(getSingleGoal.pending, state => {
        state.isFetching = true;
      })
      .addCase(getSingleGoal.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(getSingleGoal.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.data.current = {
          ...state.data.current,
          ...action.payload,
        };
        state.fulfilledAction = FulfilledEnum.GET;
      })
      // Note: Create goal
      .addCase(createGoal.pending, state => {
        state.isFetching = true;
      })
      .addCase(createGoal.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(createGoal.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.fulfilledAction = FulfilledEnum.CREATE;
        state.errorMessage = "";
        state.data.current = action.payload;
        state.data.all = [...state.data.all, action.payload];
      })
      // Note: Publish
      .addCase(publishGoal.pending, state => {
        state.isFetching = true;
      })
      .addCase(publishGoal.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(publishGoal.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current.isPublished = true;
        state.fulfilledAction = FulfilledEnum.UPDATE;
      })
      // Note: Unpublish
      .addCase(unpublishGoal.pending, state => {
        state.isFetching = true;
      })
      .addCase(unpublishGoal.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(unpublishGoal.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current.isPublished = false;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
      })
      // Note: Update
      .addCase(updateGoal.pending, state => {
        state.isFetching = true;
      })
      .addCase(updateGoal.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(updateGoal.fulfilled, (state, action) => {
        const foundIndex = state.data.all.findIndex(goal => goal.id === action.payload.id);

        if (foundIndex !== -1) {
          state.data.current = action.payload;
        }

        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
      });
  },
});

export const goalsActions = {
  clear: goalsSlice.actions.clearState,
  getGoals,
  getSingleGoal,
  createGoal,
  publishGoal,
  unpublishGoal,
  updateGoal,
};

export const goalsSelector = (state: RootState): GoalsNewState => state.goals;

export default goalsSlice.reducer;
