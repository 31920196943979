import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginatedDataBase, PaginatedState, Sort } from "src/types/base";
import { baseInitialPageState, baseInitialState } from "../../constants";
import {
  deleteCollectionService,
  fetchCollectionsService,
  fetchSingleCollectionArticlesService,
  fetchSingleCollectionService,
  getAllArticleIdsForCurrentCollection,
  postCollectionService,
  publishCollectionService,
  unpublishCollectionService,
  updateCollectionService,
} from "../../services/collections";
import {
  Collection,
  CollectionArticles,
  CollectionArticlesBody,
  CollectionBody,
  CollectionsState,
  CurrentCollection,
} from "../../types/collections";
import { FulfilledEnum } from "../../types/general";
import { RootState } from "../index";

export type PaginatedCollectionsState = Sort<Collection> & {
  all: Collection[];
  current: CurrentCollection | null;
  selectedArticles: string[];
};
export type PaginatedCollectionsData = PaginatedCollectionsState & PaginatedDataBase;
type CollectionsNewState = PaginatedState<PaginatedCollectionsData>;

export enum CollectionsSortValue {
  title = "TITLE",
  isPublished = "IS_PUBLISHED",
}

const initialState: CollectionsNewState = {
  data: {
    all: [],
    current: null,
    order: "asc",
    sortBy: "title",
    selectedArticles: [],
    ...baseInitialPageState,
  },
  ...baseInitialState,
};

// Note: GET ALL
const getCollections = createAsyncThunk<Collection[], void, { state: RootState; rejectValue: string }>(
  "collections/fetch",
  async (_, thunk) => {
    try {
      const collections = await fetchCollectionsService();

      return collections;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// // Note: GET SINGLE
const getSingleCollection = createAsyncThunk<Collection, string, { state: RootState; rejectValue: string }>(
  "collection/fetch_single",
  async (collectionId, thunk) => {
    try {
      const collection = await fetchSingleCollectionService(collectionId);

      return collection;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// Note: GET SINGLE COLLECTION ARTICLES
const getSingleArticleCollection = createAsyncThunk<
  CollectionArticles,
  CollectionArticlesBody,
  { state: RootState; rejectValue: string }
>(
  "collections/fetch_single/articles",
  async (body, thunk) => {
    try {
      const collectionArticles = await fetchSingleCollectionArticlesService(body);

      return {
        all: collectionArticles.data,
        perPage: body.perPage,
        page: body.page,
        totalElements: collectionArticles.totalElements,
      };
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// Note: CREATE
const createCollection = createAsyncThunk<Collection, CollectionBody, { state: RootState; rejectValue: string }>(
  "collections/create",
  async (body, thunk) => {
    try {
      const collection = await postCollectionService(body);

      return collection;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// Note: PUBLISH
const publishCollection = createAsyncThunk<void, string, { state: RootState; rejectValue: string }>(
  "collections/publish",
  async (collectionId, thunk) => {
    try {
      await publishCollectionService(collectionId);
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// Note: UNPUBLISH
const unpublishCollection = createAsyncThunk<void, string, { state: RootState; rejectValue: string }>(
  "collections/unpublish",
  async (collectionId, thunk) => {
    try {
      await unpublishCollectionService(collectionId);
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// Note: UPDATE
const updateCollection = createAsyncThunk<CurrentCollection, CollectionBody, { state: RootState; rejectValue: string }>(
  "collections/update",
  async (body, thunk) => {
    try {
      const updatedCollection = await updateCollectionService(body);

      return updatedCollection;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { collections } = getState();

      return !collections.isFetching;
    },
  }
);

// Note: delete a collection
const deleteCollection = createAsyncThunk<string, string, { state: RootState; rejectValue: string }>(
  "collections/delete",
  async (collectionId, thunk) => {
    try {
      await deleteCollectionService(collectionId);

      return collectionId;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  }
);

//Note: Add selected articles
const getSelectedArticles = createAsyncThunk<string[], string, { state: RootState; rejectValue: string }>(
  "collections/addselected",
  async (collectionId, thunk) => {
    try {
      const selectedArticles = await getAllArticleIdsForCurrentCollection(collectionId);

      return selectedArticles;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }
      throw new Error(e.message);
    }
  }
);

const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    clearState: (state, { payload = false }: PayloadAction<boolean>) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;

      if (payload) {
        state.data.current = null;
        state.data.selectedArticles = [];
      }
    },
  },
  extraReducers: builder => {
    builder

      // Note: Get all
      .addCase(getCollections.pending, state => {
        state.isFetching = true;
      })
      .addCase(getCollections.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getCollections.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.all = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      })

      // Note: Get Single
      .addCase(getSingleCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(getSingleCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getSingleCollection.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current = {
          ...state.data.current,
          ...action.payload,
        };
        state.fulfilledAction = FulfilledEnum.GET;
      })

      // Note: Get Single collection articles
      .addCase(getSingleArticleCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(getSingleArticleCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getSingleArticleCollection.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current = {
          ...state.data.current,
          articles: action.payload,
        };
        state.fulfilledAction = FulfilledEnum.GET;
      })

      // Note: Create collection
      .addCase(createCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(createCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(createCollection.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.fulfilledAction = FulfilledEnum.CREATE;
        state.data.all.push(action.payload);
      })

      // Note: Publish
      .addCase(publishCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(publishCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(publishCollection.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current.isPublished = true;
        state.fulfilledAction = FulfilledEnum.UPDATE;
      })

      // Note: Unpublish
      .addCase(unpublishCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(unpublishCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(unpublishCollection.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current.isPublished = false;
        state.fulfilledAction = FulfilledEnum.UPDATE;
      })

      // Note: Update
      .addCase(updateCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(updateCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(updateCollection.fulfilled, (state, action) => {
        const foundIndex = state.data.all.findIndex(collection => collection.id === action.payload.id);

        if (foundIndex !== -1) {
          state.data.current = action.payload;
        }

        state.isFetching = false;
        state.isSuccess = true;
        state.fulfilledAction = FulfilledEnum.UPDATE;
      })
      .addCase(deleteCollection.pending, state => {
        state.isFetching = true;
      })
      .addCase(deleteCollection.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(deleteCollection.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.all.filter(collection => collection.id !== action.payload);
        state.fulfilledAction = FulfilledEnum.DELETE;
      })

      // Note: Selected articles
      .addCase(getSelectedArticles.pending, state => {
        state.isFetching = true;
      })
      .addCase(getSelectedArticles.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getSelectedArticles.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.selectedArticles = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      });
  },
});

export const collectionsActions = {
  clear: collectionsSlice.actions.clearState,
  createCollection,
  deleteCollection,
  getCollections,
  getSingleArticleCollection,
  getSingleCollection,
  publishCollection,
  unpublishCollection,
  updateCollection,
  getSelectedArticles,
};

export const collectionsSelector = (state: RootState): CollectionsState => state.collections;

export default collectionsSlice.reducer;
