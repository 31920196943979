import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { baseInitialState, baseInitialPageState } from "../../constants";
import { getOrganizationInvitedUsersService, getOrganizationUsersService } from "../../services/users";
import { PaginatedDataBase, PaginatedState, PaginationWithSorting, Sort } from "../../types/base";
import { FulfilledEnum } from "../../types/general";
import { InvitedUser, User } from "../../types/user";
import { RootState } from "../index";

export enum UsersSortValue {
  firstName = "FIRST_NAME",
  lastName = "LAST_NAME",
  dateOfBirth = "BIRTH_DATE",
  gender = "GENDER",
  email = "EMAIL",
  mobileNumber = "PHONE",
  earnings = "EARNINGS",
  ethnicity = "ETHNICITY",
}

export enum InvitedUsersSortValue {
  code = "CODE",
  receiver = "RECEIVER",
  validUntil = "VALID_UNTIL",
}

export enum InvitedUsers {}

export type PaginatedUsersData = PaginatedDataBase &
  Sort<User> & {
    all: User[];
    invited: InvitedUser[];
  };

type UsersState = PaginatedState<PaginatedUsersData>;

const initialState: UsersState = {
  data: {
    all: [],
    invited: [],
    order: "asc",
    sortBy: "firstName",
    ...baseInitialPageState,
  },
  ...baseInitialState,
};

// NOTE: GET ALL FROM ORGANIZATION
const getUsersFromOrganization = createAsyncThunk<
  PaginatedUsersData,
  PaginationWithSorting<User> & { id: string },
  { state: RootState; rejectValue: string }
>("users/get_all", async ({ page = 0, perPage = 10, id, order, sortBy }, thunk) => {
  try {
    const { users: usersState } = thunk.getState();
    const users = await getOrganizationUsersService(id, page, perPage, order, sortBy);
    return {
      all: users.data,
      page,
      perPage,
      totalElements: users.totalElements,
      invited: usersState.data.invited,
      order,
      sortBy,
    };
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status === 401) {
        return thunk.rejectWithValue("You are unauthorized to make such request");
      }

      if (error.response.status === 403) {
        return thunk.rejectWithValue("You cannot make such request");
      }

      if (error.response.status === 404) {
        return thunk.rejectWithValue("Organization was not found");
      }
    }
    return thunk.rejectWithValue("Something went wrong");
  }
});

const getInvitedUsersFromOrganization = createAsyncThunk<
  PaginatedUsersData,
  PaginationWithSorting<InvitedUser> & { id: string },
  { state: RootState; rejectValue: string }
>("users/get_invited", async ({ page = 0, perPage = 10, id, order, sortBy }, thunk) => {
  try {
    const valueToSortBy = InvitedUsersSortValue[sortBy];
    const { users: usersState } = thunk.getState();
    const invitedUsers = await getOrganizationInvitedUsersService(id, page, perPage, order, sortBy);

    return {
      all: usersState.data.all,
      page,
      perPage,
      totalElements: invitedUsers.totalElements,
      invited: invitedUsers.data,
      order,
      sortBy: valueToSortBy,
    };
  } catch (e) {
    const error = e as AxiosError;
    if (error.response) {
      if (error.response.status === 401) {
        return thunk.rejectWithValue("You are unauthorized to make such request");
      }

      if (error.response.status === 403) {
        return thunk.rejectWithValue("You cannot make such request");
      }

      if (error.response.status === 404) {
        return thunk.rejectWithValue("Organization was not found");
      }
    }
    return thunk.rejectWithValue("Something went wrong");
  }
});

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearState: (state, { payload = false }: PayloadAction<boolean>) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;

      if (payload) {
        state.data.all = [];
        state.data.invited = [];
      }
    },
  },
  extraReducers: builder => {
    builder
      // NOTE: GET ALL
      .addCase(getUsersFromOrganization.pending, state => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getUsersFromOrganization.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getUsersFromOrganization.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      })
      // NOTE: GET INVITED
      .addCase(getInvitedUsersFromOrganization.pending, state => {
        state.isFetching = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getInvitedUsersFromOrganization.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        if (action.payload) {
          state.errorMessage = action.payload;
        } else {
          state.errorMessage = action.error.message;
        }
      })
      .addCase(getInvitedUsersFromOrganization.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.fulfilledAction = FulfilledEnum.GET;
      });
  },
});

export const userActions = {
  getInvited: getInvitedUsersFromOrganization,
  getAll: getUsersFromOrganization,
  clear: userSlice.actions.clearState,
};

export const userSelector = (state: RootState): UsersState => state.users;

export default userSlice.reducer;
