import { Business, CheckCircle, Dashboard, Description, LibraryBooks, PlayCircleFilled } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { paths } from "../constants";

type BaseSideItem = {
  title: string;
  path: string;
  icon?: JSX.Element;
};

type SideItem = BaseSideItem & {
  children?: BaseSideItem[];
};

type NavSection = {
  title: string;
  items: SideItem[];
};

const useNavSections = (): NavSection[] => {
  const { t } = useTranslation();

  return [
    {
      title: "",
      items: [
        {
          title: t("navigation:dashboard"),
          path: paths.root,
          icon: <Dashboard fontSize="small" />,
        },
        {
          title: t("navigation:organizations"),
          path: `/${paths.organizations}`,
          icon: <Business fontSize="small" />,
        },
        {
          title: t("navigation:articles"),
          path: `/${paths.articles}`,
          icon: <Description fontSize="small" />,
        },
        {
          title: t("navigation:collections"),
          path: `/${paths.collections}`,
          icon: <LibraryBooks fontSize="small" />,
        },
        {
          title: t("navigation:goals"),
          path: `/${paths.goals}`,
          icon: <CheckCircle fontSize="small" />,
        },
        {
          title: t("navigation:actions"),
          path: `/${paths.actions}`,
          icon: <PlayCircleFilled fontSize="small" />,
        },
      ],
    },
  ];
};

export default useNavSections;
