import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baseInitialPageState, baseInitialState } from "../../constants";
import ActionsService from "../../services/actions";
import { Actions, ActionTypes } from "../../types/actions";
import { PaginatedDataBase, PaginatedState, PaginationWithSorting, Sort } from "../../types/base";
import { FulfilledEnum } from "../../types/general";
import { secondsToCurrentMeasure } from "../../utils/functions";
import { RootState } from "../index";

export type ActionsSortedState = Sort<Actions> & {
  all: Actions[];
  current: Actions | null;
};

export type PaginatedActionsState = ActionsSortedState & PaginatedDataBase;

type ActionsState = PaginatedState<PaginatedActionsState>;

const initialState: ActionsState = {
  data: {
    all: [],
    current: null,
    order: "asc",
    sortBy: "title",
    ...baseInitialPageState,
  },
  ...baseInitialState,
};

export enum ActionsSortValue {
  title = "TITLE",
  isPublished = "IS_PUBLISHED",
  type = "TYPE",
}

const getActions = createAsyncThunk<
  PaginatedActionsState,
  PaginationWithSorting<Actions>,
  { state: RootState; rejectValue: string }
>(
  "actions/fetch",
  async ({ page = 0, perPage = 10, order, sortBy }, thunk) => {
    try {
      const actions = thunk.getState();
      const response = await ActionsService.getActionsService(page, perPage, order, sortBy);
      const { data, totalElements } = response;

      return {
        all: data,
        page,
        perPage,
        order,
        sortBy,
        totalElements,
        current: actions.actions.data.current,
      };
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();

      return !actions.isFetching;
    },
  }
);

const getSingleAction = createAsyncThunk<Actions, string, { state: RootState; rejectValue: string }>(
  "actions/singleAction",
  async (actionId, thunk) => {
    try {
      const response = await ActionsService.getSingleActionService(actionId);

      return response;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();

      return !actions.isFetching;
    },
  }
);

const createAction = createAsyncThunk<Actions, void, { state: RootState; rejectValue: string }>(
  "actions/create",
  async (_, thunk) => {
    try {
      const { formInput } = thunk.getState();

      const body = {
        title: formInput.title as string,
        description: formInput.description as string,
        completionThreshold: Number(formInput.completionThreshold),
        durationInDays: Number(formInput.durationInDays),
        type: formInput.type as string,
        recurrence: formInput.recurrence as string,
        isDeviceNeeded: JSON.parse(formInput.isDeviceNeeded as string),
        focusTags: formInput.focusTags as string[],
        unitOfMeasure: formInput.unitOfMeasure as string,
      };

      const action = await ActionsService.postActionsService(body);

      return action;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }
      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();

      return !actions.isFetching;
    },
  }
);

const editAction = createAsyncThunk<Actions, void, { state: RootState; rejectValue: string }>(
  "actions/edit",
  async (_, thunk) => {
    try {
      const { formInput, actions } = thunk.getState();
      const actionId = actions.data.current.id;

      const body = {
        title: formInput.title as string,
        description: formInput.description as string,
        completionThreshold: Number(formInput.completionThreshold),
        durationInDays: Number(formInput.durationInDays),
        type: formInput.type as string,
        recurrence: formInput.recurrence as string,
        isDeviceNeeded: JSON.parse(formInput.isDeviceNeeded as string),
        focusTags: formInput.focusTags as string[],
        unitOfMeasure: formInput.unitOfMeasure as string,
      };

      const action = await ActionsService.editActionsService(actionId, body);

      return action;
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();

      return !actions.isFetching;
    },
  }
);

const publishAction = createAsyncThunk<Actions, string, { state: RootState; rejectValue: string }>(
  "actions/publish",
  async (actionId, thunk) => {
    try {
      await ActionsService.publishActionService(actionId);
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();

      return !actions.isFetching;
    },
  }
);

const unpublishAction = createAsyncThunk<Actions, string, { state: RootState; rejectValue: string }>(
  "actions/unpublish",
  async (actionId, thunk) => {
    try {
      await ActionsService.unpublishActionService(actionId);
    } catch (e) {
      if (e.response) {
        return thunk.rejectWithValue(e.response.message);
      }

      throw new Error(e.message);
    }
  },
  {
    condition: (_, { getState }) => {
      const { actions } = getState();

      return !actions.isFetching;
    },
  }
);

const actionsSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    clearState: (state, { payload = false }: PayloadAction<boolean>) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.fulfilledAction = null;

      if (payload) {
        state.data.current = null;
      }
    },
  },
  extraReducers: builder => {
    builder
      //GetAll
      .addCase(getActions.pending, state => {
        state.isFetching = true;
      })
      .addCase(getActions.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(getActions.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.GET;
        state.data = action.payload;
      })
      //Get Single
      .addCase(getSingleAction.pending, state => {
        state.isFetching = true;
      })
      .addCase(getSingleAction.rejected, (state, action) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(getSingleAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.GET;
        state.data.current = {
          ...action.payload,
          completionThreshold:
            action.payload.type === ActionTypes.sleep
              ? secondsToCurrentMeasure(action.payload.completionThreshold, action.payload.unitOfMeasure)
              : action.payload.completionThreshold,
        };
      })
      //Create
      .addCase(createAction.pending, state => {
        state.isFetching = true;
      })
      .addCase(createAction.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(createAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.CREATE;
        state.data.current = {
          ...action.payload,
          completionThreshold:
            action.payload.type === ActionTypes.sleep
              ? secondsToCurrentMeasure(action.payload.completionThreshold, action.payload.unitOfMeasure)
              : action.payload.completionThreshold,
        };
        state.data.all = [...state.data.all, action.payload];
      })
      //Edit
      .addCase(editAction.pending, state => {
        state.isFetching = true;
      })
      .addCase(editAction.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(editAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
        state.data.current = {
          ...action.payload,
          completionThreshold:
            action.payload.type === ActionTypes.sleep
              ? secondsToCurrentMeasure(action.payload.completionThreshold, action.payload.unitOfMeasure)
              : action.payload.completionThreshold,
        };
      })
      //Publish
      .addCase(publishAction.pending, state => {
        state.isFetching = true;
      })
      .addCase(publishAction.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(publishAction.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current.isPublished = true;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
      })
      //Unpublish
      .addCase(unpublishAction.pending, state => {
        state.isFetching = true;
      })
      .addCase(unpublishAction.rejected, (state, action) => {
        state.isError = true;
        state.isFetching = false;
        state.errorMessage = action.payload ? action.payload : action.error.message;
      })
      .addCase(unpublishAction.fulfilled, state => {
        state.isFetching = false;
        state.isSuccess = true;
        state.data.current.isPublished = false;
        state.errorMessage = "";
        state.fulfilledAction = FulfilledEnum.UPDATE;
      });
  },
});

export const actionActions = {
  clear: actionsSlice.actions.clearState,
  createAction,
  editAction,
  getActions,
  getSingleAction,
  publishAction,
  unpublishAction,
};

export const actionsSelector = (state: RootState): ActionsState => state.actions;

export default actionsSlice.reducer;
