import { AxiosResponse } from "axios";
import { ArticleSortValue } from "src/store/slices/articles";
import { InvitedUsersSortValue, UsersSortValue } from "src/store/slices/users";
import { OrderType } from "src/types/base";
import parachuteApi from "../lib/axios";
import { PaginatedAndSorted } from "../types/base";
import { InvitedUser, User } from "../types/user";

export const getOrganizationUsersService = async (
  organizationId: string,
  page: number,
  perPage: number,
  order: OrderType,
  sortBy: keyof User
): Promise<PaginatedAndSorted<User, ArticleSortValue>> => {
  try {
    const valueToSortBy = UsersSortValue[sortBy];
    const response = await parachuteApi.get<PaginatedAndSorted<User, ArticleSortValue>>(
      `/v1/organizations/${organizationId}/users`,
      {
        params: {
          page,
          perPage,
          order: order.toUpperCase(),
          sortBy: valueToSortBy,
        },
      }
    );

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const getOrganizationInvitedUsersService = async (
  organizationId: string,
  page: number,
  perPage: number,
  order: OrderType,
  sortBy: keyof InvitedUser
): Promise<PaginatedAndSorted<InvitedUser, ArticleSortValue>> => {
  try {
    const valueToSortBy = InvitedUsersSortValue[sortBy];
    const response = await parachuteApi.get<PaginatedAndSorted<InvitedUser, ArticleSortValue>>(
      `/v1/organizations/${organizationId}/invitations`,
      {
        params: {
          page,
          perPage,
          order: order.toUpperCase(),
          sortBy: valueToSortBy,
        },
      }
    );

    return response.data;
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};

export const resetPassword = async (token: string, newPassword: string): Promise<AxiosResponse> =>
  await parachuteApi.post(`/v1/users/${token}/reset-password`, {
    newPassword,
  });
